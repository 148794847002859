import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";

const CreateLeague = () => (
  <HowToLayout>
    <h2>Creating a League</h2>
    <section className="documentation-section">
      <h3 id="get-started">Getting started</h3>
      <p>
        It's time for the fun stuff! This guide will go over in detail how to
        use the <span className="emph">Create League</span> form. If you have
        not yet purchased a league, check out our{" "}
        <Link className="inline-link" to="/how-to/organizers/pricing">
          Pricing
        </Link>{" "}
        page for more info.
      </p>
      <h5 style={{ marginBottom: 10 }}>First Things First:</h5>
      <ol>
        <li>Open the 4BAGR app</li>
        <li>Open the app menu (top right)</li>
        <li>Select 'Create League'</li>
      </ol>
      <div className="youtube-vid-wrap">
        <iframe
          width="100%"
          height="100%"
          style={{ position: "absolute" }}
          src="https://www.youtube.com/embed/1cJLIxCJ6qc"
          title="Create League Walkthrough video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <p>
        Below, you will find a detailed explanation for every option within the{" "}
        <span className="emph">Create League</span> form:
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="league-name">League Name</h3>
      <p>
        This one is self explanatory for the most part. You'll want a
        descriptive league name so users can easily find it while using the{" "}
        <span className="emph">League Finder</span> search.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="search-location">League Location</h3>
      <p>
        The location field is connected directly to{" "}
        <span className="emph">Google Maps</span>, meaning it should be able to
        find any registered address in their database. This is a required field
        necessary for league creation. Players can also search by this address
        when using <span className="emph">League Finder</span>.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="url">Website & Facebook URL</h3>
      <p>
        The Website and Facebook URL's will display on your league info screen
        in the form of a button for users to navigate directly to your website
        or facebook page. Make sure to remember to add the 'https://' at the
        beginning of the URL so that these function properly.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="description">League Description</h3>
      <p>
        The League description will display on your league info page in the app.
        This can be as long as you would like and generally is used by our
        organizers to describe and outline how the league will work and what is
        expected. You may put any kind of information that you would like in
        here and it will be formatted in the app exactly the way that it's
        displayed when you're finished.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="organizer">League Organizer</h3>
      <p>
        As the admin (creator) of your league, you will always have admin access
        - meaning you have total control over scores, teams, settings, etc.
        Often times an admin will create their league but have a helper or
        another organizer that actually runs the league for them. This is where
        you will input that persons name (must be a 4BAGR registered user). Once
        you have designated an organizer, they too will have admin rights for
        your league and will be able to edit every aspect of the league. Make
        sure you trust who you're giving organizer rights to!
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="entry">Entry Fee</h3>
      <p>
        The Entry Fee is the total cost per team for entry into the league. This
        is can be left at 0 if the entry is free. 4BAGR does NOT handle entry
        fees, so these must be collected outside of the app. This field will
        simply display in your league info screen as to give players an idea of
        cost of entry.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="start-date">Start Date and Time</h3>
      <p>
        This will be the start date and time of your league (shocking, I know),
        meaning the schedule is built off of this date and time so make sure you
        have it right or you'll need to delete your schedule to update this!
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="weeks">Number of Weeks</h3>
      <p>
        The length in weeks that your league will run. If you run an 8 week
        league, but on the 8th week you run a tournament, then you'll want to
        set your week length to 7 so the schedule does not generate a league
        week on your tournament week.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="courts">Number of Courts</h3>
      <p>
        The number of boards that you'll run on a nightly basis. It's
        recommended to not have as many or more courts than total teams that
        could possibly play at one time (don't have 8 courts with only 12
        teams), as this will mess up the round robin scheduling.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="teams">Number of Teams</h3>
      <p>
        This is the max amount of teams that you'd like your league to accept.
        If you set your league up to allow players to register their own teams,
        it will cap registration at the number you set for this field so that it
        doesn't overflow. You CAN start a league with fewer than the number of
        teams selected here, so don't worry if it doesn't fill up. Find more
        info on teams in the{" "}
        <Link className="inline-link" to="/how-to/organizers/teams-players">
          Teams and Players How-To
        </Link>
        .
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="start-intervals">Start Time Intervals</h3>
      <p>
        How often you'd like your next set of matches to be run. e.g.: If your
        interval is every 30 minutes and your start time is 6:00 pm, your next
        set of start times will go as follows: 6:30 pm, 7:00 pm, 7:30 pm, etc.
        Rule of thumb is 30 minutes per 3 game match.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="match-games">Games Per Match</h3>
      <p>
        Total number of games to be played per match. If you want your league to
        run as a best of 3, this will be set to 3. Let your players know on
        league night that the matches are best of 3 so all 3 games do NOT have
        to be played if a team wins the first 2.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="matches">Matches Per Night</h3>
      <p>
        This is the amount of times you'd like every team to play per night.
        Common setups for a 4BAGR league are 2 or 3 matches per night where the
        games are a best 2 of 3.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="standings-sort">Sort Standings</h3>
      <p>
        There's a few ways we allow organizers to sort their standings screens:
        Win %, Wins/Losses, Points For (points scored). These are all based on
        the individual games totals and not match totals.
      </p>
      <ul>
        <li>
          Win %: Most common - Sorts standings by Win % and Points For is the
          tiebreaker
        </li>
        <li>
          Win/Loss: Will sort the standings by wins, losses, then points for. Be
          careful, as the team with the most wins is not always the team with
          the best Win %.
        </li>
        <li>Points For: Sorts by total points scored from all games</li>
      </ul>
    </section>
    <section className="documentation-section">
      <h3 id="players-per">Players per team</h3>
      <p>
        You can have up to 8 players per team (great for crew cup leagues). Each
        player will have the ability to edit their match and game scores. Find
        more info on players in the{" "}
        <Link className="inline-link" to="/how-to/organizers/teams-players">
          Teams and Players How-To
        </Link>
        .
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="registration">Team Registration</h3>
      <p>
        Team Registration defaults to closed, meaning only the organizer can
        add/remove teams. If you check this box, it will update to 'Open',
        meaning players can register their team for your league themselves as
        well as have the ability to add their own partner. Open Registration is
        the 4BAGR recommended route. This will cause less headache for you as
        you will not have to input all of the teams and players yourself. You
        can always delete or update teams and players that you do not want in
        the league.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="assign-courts">Assign Courts</h3>
      <p>
        This option enables the ability for the organizer to add court
        assignments to matches from the schedule screen after the league
        schedule has been generated. You will then have the ability to assign
        courts individually or auto assign all of the courts on the schedule
        with the click of a button.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="handicaps">Allow Handicaps</h3>
      <p>
        Handicapping is built into 4BAGR if you so choose to use it. For
        detailed handicapping information please visit the{" "}
        <Link className="inline-link" to="/how-to/organizers/handicaps">
          Handicaps How-To
        </Link>{" "}
        page. Checking this box will display the max handicap that you'd like
        teams to achieve and enable the use of handicaps within your league.
        Handicaps are auto generated automatically after each game is submitted.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="payouts">Payouts</h3>
      <p>
        This section is strictly for vanity and as mentioned earlier, 4BAGR does
        not handle any in app payments between organizers and players. This
        section controls what is displayed on the league info page as your
        overall league payouts. You can add up to 10 payout spots and update the
        payouts from a percentage to a dollar value to display.
      </p>
    </section>
  </HowToLayout>
);

export default CreateLeague;
